@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: Raleway;
}

body {
  margin: 0;
  font-family: Raleway;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-carousel > .desktop-carousel {
  display: none;
  max-width: 50dvw;
  width: 100%;
  overflow: hidden;
}

.ant-carousel > .mobile-carousel {
  display: none;
}

.ant-input-number-group-addon {
  padding: 0 !important;
}

@media (max-width: 770px) {
  .ant-carousel > .desktop-carousel {
    display: block;
    width: 100%;
    overflow: hidden;
    max-width: max-content;
  }
}

@media (max-width: 1023px) {
  .ant-carousel > .mobile-carousel {
    display: block;
  }
}

@media (min-width: 1024px) {
  .ant-carousel > .desktop-carousel {
    display: block;
  }
}
